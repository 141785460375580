import axiosInstance from './AxiosIns';

export default {

    async getAllWebsiteUsers(payload) {

        let response = await axiosInstance().get(`/dashboard/website-users/all`, { params: payload });

        return response;
    },

    async getWebsiteUserDetails(payload) {
        const { user_id } = payload;
    
        let response = await axiosInstance().get(`/dashboard/website-users/details/${user_id}`);
    
        return response;
    },

}